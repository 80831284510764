/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #ffffff; /* Set the body background to white */
  color: #000000; /* Set the default text color to black */
  font-family: 'Centra', sans-serif !important; /* Ensure font consistency */
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/* App.css */
/* App.css */
/* .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.NavBar {
  flex: none; /* Prevent the navigation bar from growing */
/* } */

/* .App-content {
  flex: 1; /* Allow the content area to fill the remaining space */
  /* padding-top: 225px; /* Adjust the padding to provide space for the navigation bar */
/* } */ 

/* App.css */
/* App.css */
/* .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.NavBar {
  flex: none; /* Prevent the navigation bar from growing */
/* } */

/* .App-content {
  flex: 1; /* Allow the content area to fill the remaining space */
  /* padding-top: 225px; /* Adjust the padding to provide space for the navigation bar */
/* } */ 

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.App,.App-content{
  flex: 1;
}
/************ Navbar Css ************/























.navbar-collapse.show {
  background-color: #fff; /* Replace #121212 with the desired color */
}

.navbar-brand img {
  height: 120px; /* Adjust the height as needed */
  width: auto; /* Ensure the image scales proportionally */
}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  /* background-color: #ffffff; Set navbar background to white */
  /* border-bottom: 1px solid #e0e0e0; Optional: Add a subtle border */
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #ffffff; /* Set scrolled navbar background to white */
  border-bottom: 1px solid #e0e0e0;


  
}
nav.navbar a.navbar-brand {
    width: 12%;
}

nav.navbar .navbar-nav .nav-link.navbar-link ,#basic-nav-dropdown{
  font-weight: 400;
  color: #000000 !important; /* Set navbar text color to black */
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav .nav-link.navbar-link.scrolled, #basic-nav-dropdown-scrolled{
  font-weight: 500;
  color: #000000 !important; /* Set navbar text color to black */
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;  
  opacity: 0.75; /* Color when scrolled */
}

#basics-nav-dropdown, #basic-nav-dropdown .dropdown-menu{
  font-weight: 400;
  color: #000000 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}


#basic-nav-dropdown:hover {
  opacity: 1 !important;
  
} 
.nav-dropdown-menu .dropdown-item {
  color: #000000; /* Change text color to ensure it is readable */
}

.nav-dropdown-menu .dropdown-item:hover {
  color: #585757; /* Slightly lighter black for hover effect */
}

/* Custom CSS for Navbar hover effect */
#projects-nav-dropdown .dropdown-menu {
  display: none;
  background-color: #fff; /* Background color of dropdown */
  /* border: 1px solid #ddd; Border color */
  border-radius: 0px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dropdown */
  margin-top: 0;
  padding: 10px 5px; /* Padding inside dropdown */
  transition: opacity 0.3s ease; /* Smooth transition */
  opacity: 0;
  pointer-events: none;
}

#projects-nav-dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1; /* Full opacity on hover */
  pointer-events: auto;
}

/* Styling for dropdown items */
#projects-nav-dropdown .dropdown-item {
  padding: 10px 20px; /* Padding for items */
  color: #000000; /* Text color */
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
} 






nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
nav-dropdown-menu:hover{
  opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(9, 9, 9, 0.5);
}

.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #000;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
   
}

.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
  
}

 

.social-icon a img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.social-icon a img:hover {
	filter: none;
}



.navbar-text button.scrolled {  
  font-weight: 700;
  color: #000000;
  border: 1px solid #000000;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button {
  font-weight: 700;
  color: #000;
  border: 1px solid #000;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #ffffff;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #000000;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #000000;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #ffffff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background-image: url('./assets/img/banner-bg.png'); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  
  color: #000; /* Set banner text color to black */
  
  text-align: left; /* Optional: Center text */
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
/* .banner h2 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
} */
.banner p {
  color: #000;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1em;
  width: 100%;
}


.banner button {
  
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  
}

/* Banner.css */
.custom-link {
  text-decoration: none;
}




.banner button svg {
  
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
  
}
.banner button:hover svg {
  margin-left: 25px;

}
.banner img {
  animation: zoom 3s ease-in-out infinite alternate;
  transform: translateZ(0);
}
@keyframes zoom {
  0% {
    transform: scale(1); /* Normal size */
  }
  100% {
    transform: scale(1.1); /* Zoomed in */
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
/* .about-us {
  padding: 0 0 50px 0;
  position: relative;
} */
/* .about-uss {
  background: #151515;
 
  text-align: center;
  padding: 60px 50px;
  margin-top: 180px;
} */

.about-uss {
  padding: 50px 0;
  text-align: center;
  min-height: 100vh; /* Set minimum height to fill the viewport */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #000; */
  background-color: #ffffff; /* Set Skills section background to white */
  color: #000000; /* Set text color to black */
  padding: 20px; /* Optional: Add padding for spacing */
}

.about-uss h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.about-uss .content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.about-uss p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-uss h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.about-uss .mission {
  list-style: none;
  padding-left: 0;
}

.about-uss .mission li {
  margin-bottom: 10px;
  padding-left: 20px;
}

.about-uss .mission li:before {
  content: "➔";
  margin-right: 10px;
  font-size: 0.8em; /* Adjust the size as needed */
  color: #ddd; /* Adjust color as needed */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .about-uss h1 {
    font-size: 2em;
  }

  .about-uss h2 {
    font-size: 1.5em;
  }

  .about-uss .content {
    padding: 0 20px;
  }
}



.about-us {
  background-image: url('./assets/img/aboutus.png');
  margin-top: 0;
  padding: 450px 0 100px 0;
  /* background-color: #ffffff; Set About Us section background to white */
  /* color: #000000; Set text color to black */
  /* padding: 20px; Optional: Add padding for spacing
   */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}









/************ Projects Css ************/



.project {
  padding: 80px 0;
  position: relative;
  
  background-color: #ffffff; /* Set Projects section background to white */
  color: #000000; /* Set text color to black */
  padding: 20px; /* Optional: Add padding for spacing */
}
.project_margin {
  margin-top: 100px;

}
.project h2 {
  color: #000;
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 300px;
  width: 450px;
}

.proj-imgbx::before {
  content: "";
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.5s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact Css ************/
.contact_margin{
  margin-top: 100px;

}
.contact {
  
  padding: 60px 0 200px 0;
  background-color: #ffffff; /* Set Contact section background to white */
  color: #000000; /* Set text color to black */
  /* padding: 20px; Optional: Add padding for spacing */
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(32, 30, 30, 0.1);
  border: 1px solid rgba(34, 32, 32, 0.5);
  border-radius: 20px;
  color: #000000;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
/* Footer.css */

.footer {

  background-color: #ffffff; /* Set footer background to white */
  color: #000000; /* Set footer text color to black */
  padding: 20px; /* Optional: Add padding for spacing */
  border-top: 1px solid #e0e0e0; /* Optional: Add a subtle border */
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 10px;
}

.footer-logo img {
  margin-right: 10px;
 
 margin-top: 10px;
  max-width: 200px;
  height: auto;
}
.footer-address{
  margin-right: 100px;
}
.footer-address,
.footer-quick-links,
.footer-products {
  flex: 1;
  margin: 20px;
}

.footer-address p,
.footer-quick-links ul,
.footer-products ul {
  margin: 10px 0;
  font-size: 18px;
}

.footer-quick-links ul,
.footer-products ul {
  list-style-type: none;
  padding: 0;
}

.footer-quick-links ul li a,
.footer-products ul li a {
  text-decoration: none;
  color: #000000;
}

.footer-quick-links ul li a:hover,
.footer-products ul li a:hover {
  text-decoration: underline;
}
/* @media(max-width:480px) {
  .footer-address p,
.footer-quick-links ul,
.footer-products ul {
  margin: 10px 0;
  font-size: 15px;
}

} */




.project_page * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* Set project page background to white */
  color: #000000; /* Set text color to black */
  /* padding: 20px; Optional: Add padding for spacing */
}
.photo-content{
  font-size: 15px;
}
.project_page body {
  font-family: Arial, sans-serif;
  
}
.project_page_margin{
  /* margin-top: 150px; */
  background-color: #ffffff;

}
.project_page {
  display: flex;
}
.project_page p{
  font-size: 15px;
}
.project_page .menu { 
  overflow-y: auto; 
  
  width: 20%;
  background-color: #ffffff;
  color: #000000;
  padding: 20px;

    position: sticky;
    /* margin-top: 200px; */
    max-height: 750px;  
  
}

.project_page .menu ul {  
  list-style-type: none;
  
}
.project_page .menu .heading {
 
  font-size: 30px;
  font-weight: 100px;
  
}

.project_page .menu li {
  padding: 30px 0;
  cursor: pointer;
  color: #000000;
  font-size: 15px;
  text-align: center;
  
} 

.project_page .menu li button{

  font-size: 15px;
  color: #000000;
  

} 


.project_page .menu li:hover {
  color: #575757;
}

.project_page .menu .heading:hover {
  background-color: #ffffff;
  
}

.project_page .content {

  scroll-behavior: smooth;
  width: 80%;
  padding: 20px;
  background-color: #ffffff;
}

.project_page h1 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.project_page table {
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;
}

.project_page table, th, td {
  border: 3px solid #000000;
  padding: 16px;
  text-align: center;
  font-size: 15px;
}
.project_page .content li {

  margin: 10px 30px;
  padding: 10px;
  font-size: 15px;

}

.project_page th, .project_page td {
  padding: 10px; /* Add padding to cells */
  border: 1px solid #000000; /* Add borders to cells */
  text-align: center; /* Align text to the left */
}

.project_page th {
  background-color: #ffffff;
  font-weight: bold;
  /* border-left: 3px solid #000000; */
  
}


.project_page2 {
  margin-top: 0;
  padding: 400px 0 100px 0;
  color: #ffffff;
  background-image: url('./assets/img/pp2.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project_page3 {
  margin-top: 0;
  color: #ffffff;
  padding: 400px 0 100px 0;
  background-image: url('./assets/img/pp3.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project_page4 {
  margin-top: 0;
  color: #ffffff;
  padding: 400px 0 100px 0;
  background-image: url('./assets/img/pp4.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
} 

.project_page5 {
  margin-top: 0;
  color: #ffffff;
  padding: 400px 0 100px 0;
  background-image: url('./assets/img/pp5.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
} 

.project_page1 {
  margin-top: 0;
  padding: 400px 0 100px 0;
  background-image: url('./assets/img/pp1.png');
  color: #ffffff;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 450px){
  .project_page2 {
    margin-top: 0;
    padding: 150px 0 100px 0;
    background-image: url('./assets/img/pp2.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .project_page3 {
    margin-top: 0;
    padding: 150px 0 100px 0;
    background-image: url('./assets/img/pp3.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  .project_page4 {
    margin-top: 0;
    padding: 150px 0 100px 0;
    background-image: url('./assets/img/pp4.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  .project_page5 {
    margin-top: 0;
    padding: 150px 0 100px 0;
    background-image: url('./assets/img/pp5.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  .project_page1 {
    margin-top: 0;
    padding: 150px 0 100px 0;
    background-image: url('./assets/img/pp1.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
}




.pp3_table {
  width: 100%; /* Make the table fill the container */
  border-collapse: collapse; /* Collapse the borders between cells */
  border-spacing: 0; /* Set the space between table cells to zero */
}
.pp3_table td, th {
  padding: 10px; /* Add padding to cells */
  border: 1px solid #000000; /* Add borders to cells */
  text-align: center; /* Align text to the left */
}
.pp3_table th {
  background-color: #ffffff; /* Background color for header cells */
}

.table_pp4 td, th {
  padding: 10px; /* Add padding to cells */
  border: 1px solid #000000; /* Add borders to cells */
  text-align: center; /* Align text to the left */
}
.table_pp4 th {
  background-color: #ffffff; /* Background color for header cells */
}
.table_pp4{
  width: 100%; /* Make the table fill the container */
  border-collapse: collapse; /* Collapse the borders between cells */
  border-spacing: 0; /* Set the space between table cells to zero */
}



@media only screen and (max-width: 768px) {
  .project_page table, 
  .project_page th, 
  .project_page td, 
  .pp3_table td, 
  .pp3_table th, 
  .table_pp4 td, 
  .table_pp4 th,
  .project_page .menu li button ,
  .menuu .heading{
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .project_page table, 
  .project_page th, 
  .project_page td, 
  .pp3_table td, 
  .pp3_table th, 
  .table_pp4 td, 
  .table_pp4 th ,
  .project_page .menu li button,
  .menuu .heading{
    font-size: 14px;
    
  }



  
}

@media only screen and (max-width: 480px) {
  .project_page table, 
  .project_page th, 
  .project_page td, 
  .pp3_table td, 
  .pp3_table th, 
  .table_pp4 td, 
  .table_pp4 th,
  .project_page .menu li button,
  .menuu .heading {
    font-size: 12px;
    
  }
}



/* Quality CSS */

.quality {
  padding: 50px 0;
  text-align: center;
  min-height: 100vh; /* Set minimum height to fill the viewport */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.quality h1 {
  color: #000;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.quality .content {
  color: #000;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.quality p {
  color: #000;
  line-height: 1.6;
  margin-bottom: 20px;
}

.quality h2 {
  color: #000;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.quality .mission {
  list-style: none;
  padding-left: 0;
}

.quality .mission li {  
  margin-bottom: 10px;
  padding-left: 20px;
}

.quality .mission li:before {
  content: "➔";
  margin-right: 10px;
  font-size: 0.8em; /* Adjust the size as needed */
  color: #ddd; /* Adjust color as needed */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .quality h1 {
    font-size: 2em;
  }

  .quality h2 {
    font-size: 1.5em;
  }

  .quality .content {
    padding: 0 20px;
  }
}
@media(max-width:480){
  .quality h1 {
    font-size: 1.5em;
  }

  .quality h2 {
    font-size: 1.2em;
  }

  .quality .content {
    padding: 0 20px;
  }
  .quality-img{
    
    padding: 150px 0 10px 0;

  }
  

}



.quality-img {
  margin-top: 0;
  padding: 450px 0 100px 0;
  background-image: url('./assets/img/quality.png');
  
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}


.project-page1, .project-page2, .project-page3, .project-page4, .project-page5 {
  background-color: #ffffff; /* Set page backgrounds to white */
  background-image: none; /* Remove background images if any */
  color: #000000; /* Set text color to black */
  padding: 20px; /* Optional: Add padding for spacing */
}









